
import {
  defineComponent, onMounted, toRefs,
} from 'vue';
import { useRouter } from 'vue-router/composables';
import Loader from '@/components/ui/Loader.vue';
import { useDi } from '@/plugins';
import { useQuizSession } from '@/domains/quiz/composables/useQuizSession';
import { Names } from '@/plugins/vue-router';
import { PlayerSessionStatus } from '@/domains/common';
import { AtomType } from '@/domains/atom';

export default defineComponent({
  name: 'AssignmentQuizGateway',

  components: { Loader },

  props: {
    playerSessionId: {
      type: Number,
      required: true,
    },
  },

  setup(props) {
    const { playerSessionId } = toRefs(props);

    const di = useDi();
    const router = useRouter();

    const {
      session, fetchSession,
    } = useQuizSession({ playerSessionId });

    onMounted(async () => {
      try {
        await fetchSession();

        switch (session.value?.playerSessionStatus) {
          case PlayerSessionStatus.UNKNOWN:
          case PlayerSessionStatus.CANCELED:
          case PlayerSessionStatus.DELETED:
          case PlayerSessionStatus.NEW:
            router.replace({
              name: Names.R_APP_LEARNING_SESSION_PLAYER_ATOM_PREVIEW,
              params: {
                atomType: AtomType.QUIZ,
              },
            });
            break;
          case PlayerSessionStatus.ACTIVE:
          default:
            router.replace({
              name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_INTRO,
            });
            break;
          case PlayerSessionStatus.COMPLETED:
            router.replace({
              name: Names.R_APP_LEARNING_SESSION_PLAYER_QUIZ_RESULT,
            });
            break;
        }
      } catch (e: any) {
        console.error(e);

        await router.back();

        di.notify.error({ content: e.message });
      }
    });
  },
});
